import { DisabledTreeNodeDto } from "../../dtos/employeeRoles/DisabledTreeNodeDto";
import { TreeItemDto } from "../../dtos/employeeRoles/TreeItemDto";

const treeDataWithoutSelectable: TreeItemDto[] = [
  {
    key: "Pages",
    label: "Pages",
    data: "Pages",
    icon: "pi pi-fw pi-file",
    children: [
      {
        key: "Pages.Administration",
        label: "Administration",
        data: "Pages.Administration",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.Administration.Roles",
            label: "Roles",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.Roles",
            children: [
              {
                key: "Pages.Administration.Roles.EditRole",
                label: "Edit Role",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Roles.EditRole",
              },
              {
                key: "Pages.Administration.Roles.EditPermission",
                label: "Edit Permission",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Roles.EditPermission",
              },
            ],
          },
          {
            key: "Pages.Administration.Users",
            label: "Users",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.Users",
            children: [
              {
                key: "Pages.Administration.Users.EditPermission",
                label: "Edit Permission",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Users.EditPermission",
              },
            ],
          },
          {
            key: "Pages.Administration.KpiMappings",
            label: "Kpi Mappings",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.KpiMappings",
            children: [
              {
                key: "Pages.Administration.KpiMappings.RoleMappings",
                label: "Role Mappings",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.KpiMappings.RoleMappings",
                children: [
                  {
                    key: "Pages.Administration.KpiMappings.RoleMappings.CreateRoleMapping",
                    label: "Create Role Mappings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.KpiMappings.RoleMappings.CreateRoleMapping",
                  },
                  {
                    key: "Pages.Administration.KpiMappings.RoleMappings.EditRoleMapping",
                    label: "Edit Role Mappings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.KpiMappings.RoleMappings.EditRoleMapping",
                  },
                ],
              },
              {
                key: "Pages.Administration.KpiMappings.UserMappings",
                label: "User Mappings",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.KpiMappings.UserMappings",
                children: [
                  {
                    key: "Pages.Administration.KpiMappings.UserMappings.CreateUserMapping",
                    label: "Create User Mappings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.KpiMappings.UserMappings.CreateUserMapping",
                  },
                  {
                    key: "Pages.Administration.KpiMappings.UserMappings.EditUserMapping",
                    label: "Edit User Mappings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.KpiMappings.UserMappings.EditUserMapping",
                  },
                ],
              },
            ],
          },
          {
            key: "Pages.Administration.Kpi",
            label: "Kpi",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.Kpi",
            children: [
              {
                key: "Pages.Administration.Kpi.CreateKpi",
                label: "Create KPI",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Kpi.CreateKpi",
              },
              {
                key: "Pages.Administration.Kpi.EditKpi",
                label: "Edit KPI",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Kpi.EditKpi",
              },
              {
                key: "Pages.Administration.Kpi.DeleteKpi",
                label: "Delete KPI",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Kpi.DeleteKpi",
              },
              {
                key: "Pages.Administration.Kpi.SubKpi",
                label: "Sub-KPI",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Kpi.SubKpi",
                children: [
                  {
                    key: "Pages.Administration.Kpi.SubKpi.CreateSubKpi",
                    label: "Create Sub-KPI",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Kpi.SubKpi.CreateSubKpi",
                  },
                  {
                    key: "Pages.Administration.Kpi.SubKpi.DeleteSubKpi",
                    label: "Delete Sub-KPI",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Kpi.SubKpi.DeleteSubKpi",
                  },
                ],
              },
            ],
          },
          {
            key: "Pages.Administration.Settings",
            label: "Settings",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.Settings",
            children: [
              {
                key: "Pages.Administration.Settings.MailSettings",
                label: "Mail Settings",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.MailSettings",
              },
              {
                key: "Pages.Administration.Settings.MailTemplate",
                label: "Mail Template",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.MailTemplate",
              },
              {
                key: "Pages.Administration.Settings.AppSettings",
                label: "App Settings",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.AppSettings",
                children:[
                  {
                    key: "Pages.Administration.Settings.AppSettings.SystemAdmin",
                    label: "System Admin",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Settings.AppSettings.SystemAdmin"
                  }, {
                    key: "Pages.Administration.Settings.AppSettings.CronSettings",
                    label: "Cron Settings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Settings.AppSettings.CronSettings"
                  }, {
                    key: "Pages.Administration.Settings.AppSettings.SubordinateLevel",
                    label: "Subordinate Level",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Settings.AppSettings.SubordinateLevel"
                  }, {
                    key: "Pages.Administration.Settings.AppSettings.SystemTimeZone",
                    label: "System Time Zone",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Settings.AppSettings.SystemTimeZone"
                  },
    
                ]
              },
              {
                key: "Pages.Administration.Settings.AuditLog",
                label: "Audit Log",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.AuditLog",
              },
              {
                key: "Pages.Administration.Settings.CronJobData",
                label: "CronJob Data",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.CronJobData",
              },
              {
                key: "Pages.Administration.Settings.CronJobStatus",
                label: "CronJob Status",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.CronJobStatus",
              },
            ],
          },
        ],
      },
      {
        key: "Pages.Dashboard",
        label: "Dashboard",
        data: "Pages.Dashboard",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.Dashboard.TopBillingEmployee",
            label: "Top Billing Employee",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.TopBillingEmployee",
          },
          {
            key: "Pages.Dashboard.EmployeePerformance",
            label: "Employee Performance",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.EmployeePerformance",
          },
          {
            key: "Pages.Dashboard.EmployeeSkills",
            label: "Employee Skills",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.EmployeeSkills",
          },
          {
            key: "Pages.Dashboard.TopBillingProjects",
            label: "Top Billing Projects",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.TopBillingProjects",
          },
          {
            key: "Pages.Dashboard.TopTrackingProjects",
            label: "Top Tracking Projects",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.TopTrackingProjects",
          },{
            key: "Pages.Dashboard.YourPerformanceChart",
            label: "Your Performance Chart",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.YourPerformanceChart",
          }
        ],
      }
     ,
      {
        key: "Pages.Project",
        label: "Project",
        data: "Pages.Project",
        icon: "pi pi-fw pi-file",
      },
      {
        key: "Pages.MyTeam",
        label: "My Team",
        data: "Pages.MyTeam",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.MyTeam.Skills",
            label: "Skills",
            icon: "pi pi-fw pi-file",
            data: "Pages.MyTeam.Skills",
            children: [
              {
                key: "Pages.MyTeam.Skills.AssignSkills",
                label: "Assign Skills",
                icon: "pi pi-fw pi-file",
                data: "Pages.MyTeam.Skills.AssignSkills",
              },
            ],
          },
        ],
      },
      {
        key: "Pages.MyPerformance",
        label: "My Performance",
        data: "Pages.MyPerformance",
        icon: "pi pi-fw pi-file",
      },
     
      {
        key: "Pages.EmployeeBilling",
        label: "Employee Billing",
        data: "Pages.EmployeeBilling",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.EmployeeBilling.UpdateEmployeeBillingTime",
            label: "Update Employee Billing Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.EmployeeBilling.UpdateEmployeeBillingTime",
          },
        ],
      },
      {
        key: "Pages.ProjectBilling",
        label: "Project Billing",
        data: "Pages.ProjectBilling",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.ProjectBilling.AddProjectBillingTime",
            label: "Add Project Billing Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.ProjectBilling.AddProjectBillingTime",
          },
          {
            key: "Pages.ProjectBilling.UpdateProjectBillingTime",
            label: "Update Project Billing Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.ProjectBilling.UpdateProjectBillingTime",
          },
        ],
      },
      {
        key: "Pages.Options",
        label: "Options",
        data: "Pages.Options",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.Options.CreateOption",
            label: "Create Option",
            icon: "pi pi-fw pi-file",
            data: "ages.Administration.Options.CreateOption",
          },
          {
            key: "Pages.Options.EditOption",
            label: "Edit Option",
            icon: "pi pi-fw pi-file",
            data: "Pages.Options.EditOption",
          },
          {
            key: "Pages.Options.DeleteOption",
            label: "Delete Option",
            icon: "pi pi-fw pi-file",
            data: "Pages.Options.DeleteOption",
          },
        ],
      },
      
      {
        key: "Pages.RatingsAndReviews",
        label: "Ratings & Reviews",
        icon: "pi pi-fw pi-file",
        data: "Pages.RatingsAndReviews",
        children: [
          {
            key: "Pages.RatingsAndReviews.Score",
            label: "Score",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Score",
          },
          {
            key: "Pages.RatingsAndReviews.Weightage",
            label: "Weightage",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Weightage",
          },
          {
            key: "Pages.RatingsAndReviews.Target",
            label: "Target",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Target",
          },
          {
            key: "Pages.RatingsAndReviews.Performance",
            label: "Performance",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Review",
          },
          {
            key: "Pages.RatingsAndReviews.Role",
            label: "Role",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Role",
          },
        ],
      },
      {
        key: "Pages.PerformanceReport",
        label: "Performance Report",
        icon: "pi pi-fw pi-file",
        data: "Pages.PerformanceReport",
      },
      {
        key: "Pages.Report",
        label: "Report",
        icon: "pi pi-fw pi-file",
        data: "Pages.Report",
        children: [
          {
            key: "Pages.Report.ProjectBillableTime",
            label: "Project Billable Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.ProjectBillableTime",
          },
          {
            key: "Pages.Report.ProjectTrackableTime",
            label: "Project Trackable Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.ProjectTrackableTime",
          },
          {
            key: "Pages.Report.TotalTrackAndBillTimeOfProjects",
            label: "Total Track And Bill Time of Projects",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.TotalTrackAndBillTimeOfProjects",
          },
          {
            key: "Pages.Report.EmployeeBillableTime",
            label: "Employee Billable Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.EmployeeBillableTime",
          },
          {
            key: "Pages.Report.EmployeeTrackableTime",
            label: "Employee Trackable Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.EmployeeTrackableTime",
          },
        ],
      },
      {
        key: "Pages.Goals",
        label: "Goals",
        icon: "pi pi-fw pi-file",
        data: "Pages.Goals",
        children: [
          {
            key: "Pages.Goals.CreateGoal",
            label: "Create Goal",
            icon: "pi pi-fw pi-file",
            data: "Pages.Goals.CreateGoal",
          },
          {
            key: "Pages.Goals.EditGoal",
            label: "Edit Goal",
            icon: "pi pi-fw pi-file",
            data: "Pages.Goals.EditGoal",
          },
          {
            key: "Pages.Goals.DeleteGoal",
            label: "Delete Goal",
            icon: "pi pi-fw pi-file",
            data: "Pages.Goals.DeleteGoal",
          },
          {
            key: "Pages.Goals.GoalStep",
            label: "Goal Steps",
            icon: "pi pi-fw pi-file",
            data: "Pages.Goals.GoalStep",
            children: [
              {
                key: "Pages.Goals.GoalStep.CreateGoalStep",
                label: "Create Goal Step",
                icon: "pi pi-fw pi-file",
                data: "Pages.Goals.GoalStep.CreateGoalStep",
              },
              {
                key: "Pages.Goals.GoalStep.EditGoalStep",
                label: "Edit Goal Step",
                icon: "pi pi-fw pi-file",
                data: "Pages.Goals.GoalStep.EditGoalStep",
              },
              {
                key: "Pages.Goals.GoalStep.DeleteGoalStep",
                label: "Delete Goal Step",
                icon: "pi pi-fw pi-file",
                data: "Pages.Goals.GoalStep.DeleteGoalStep",
              },
            ],
          },
         
          
        ],
      },
      {
        key: "Pages.UserGoals",
        label: "User Goals",
        icon: "pi pi-fw pi-file",
        data: "Pages.UserGoals",
      },
      {
        key: "Pages.MyGoals",
        label: "My Goals",
        icon: "pi pi-fw pi-file",
        data: "Pages.MyGoals",
      },
      {
        key: "Pages.Skills",
        label: "Skills",
        icon: "pi pi-fw pi-file",
        data: "Pages.Skills",
        children: [
          {
            key: "Pages.Skills.CreateSkill",
            label: "Create Skill",
            icon: "pi pi-fw pi-file",
            data: "Pages.Skills.CreateSkill",
          },
          {
            key: "Pages.Skills.EditSkill",
            label: "Edit Skill",
            icon: "pi pi-fw pi-file",
            data: "Pages.Skills.EditSkill",
          },
          {
            key: "Pages.Skills.DeleteSkill",
            label: "Delete Skill",
            icon: "pi pi-fw pi-file",
            data: "Pages.Skills.DeleteSkill",
          },
        ],
      }
    ],
  },
];
const treeDataWithSelectable: DisabledTreeNodeDto[] =[
  {
    key: "Pages",
    label: "Pages",
    data: "Pages",
    icon: "pi pi-fw pi-file",
    children: [
      {
        key: "Pages.Administration",
        label: "Administration",
        data: "Pages.Administration",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.Administration.Roles",
            label: "Roles",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.Roles",
            selectable:false,
            children: [
              {
                key: "Pages.Administration.Roles.EditRole",
                label: "Edit Role",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Roles.EditRole",
                selectable:false,
              },
              {
                key: "Pages.Administration.Roles.EditPermission",
                label: "Edit Permission",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Roles.EditPermission",
                selectable:false,
              },
            ],
          },
          {
            key: "Pages.Administration.Users",
            label: "Users",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.Users",
            children: [
              {
                key: "Pages.Administration.Users.EditPermission",
                label: "Edit Permission",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Users.EditPermission",
              },
            ],
          },
          {
            key: "Pages.Administration.KpiMappings",
            label: "Kpi Mappings",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.KpiMappings",
            selectable:false,
            children: [
              {
                key: "Pages.Administration.KpiMappings.RoleMappings",
                label: "Role Mappings",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.KpiMappings.RoleMappings",
                selectable:false,
                children: [
                  {
                    key: "Pages.Administration.KpiMappings.RoleMappings.CreateRoleMapping",
                    label: "Create Role Mappings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.KpiMappings.RoleMappings.CreateRoleMapping",
                    selectable:false,
                  },
                  {
                    key: "Pages.Administration.KpiMappings.RoleMappings.EditRoleMapping",
                    label: "Edit Role Mappings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.KpiMappings.RoleMappings.EditRoleMapping",
                    selectable:false,
                  },
                ],
              },
              {
                key: "Pages.Administration.KpiMappings.UserMappings",
                label: "User Mappings",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.KpiMappings.UserMappings",
                selectable:false,
                children: [
                  {
                    key: "Pages.Administration.KpiMappings.UserMappings.CreateUserMapping",
                    label: "Create User Mappings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.KpiMappings.UserMappings.CreateUserMapping",
                    selectable:false,
                  },
                  {
                    key: "Pages.Administration.KpiMappings.UserMappings.EditUserMapping",
                    label: "Edit User Mappings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.KpiMappings.UserMappings.EditUserMapping",
                    selectable:false,
                  },
                ],
              },
            ],
          },
          {
            key: "Pages.Administration.Kpi",
            label: "Kpi",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.Kpi",
            children: [
              {
                key: "Pages.Administration.Kpi.CreateKpi",
                label: "Create KPI",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Kpi.CreateKpi",
              },
              {
                key: "Pages.Administration.Kpi.EditKpi",
                label: "Edit KPI",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Kpi.EditKpi",
              },
              {
                key: "Pages.Administration.Kpi.DeleteKpi",
                label: "Delete KPI",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Kpi.DeleteKpi",
              },
              {
                key: "Pages.Administration.Kpi.SubKpi",
                label: "Sub-KPI",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Kpi.SubKpi",
                children: [
                  {
                    key: "Pages.Administration.Kpi.SubKpi.CreateSubKpi",
                    label: "Create Sub-KPI",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Kpi.SubKpi.CreateSubKpi",
                  },
                  {
                    key: "Pages.Administration.Kpi.SubKpi.DeleteSubKpi",
                    label: "Delete Sub-KPI",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Kpi.SubKpi.DeleteSubKpi",
                  },
                ],
              },
            ],
          },
          {
            key: "Pages.Administration.Settings",
            label: "Settings",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.Settings",
            children: [
              {
                key: "Pages.Administration.Settings.MailSettings",
                label: "Mail Settings",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.MailSettings",
              },
              {
                key: "Pages.Administration.Settings.MailTemplate",
                label: "Mail Template",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.MailTemplate",
              },
              {
                key: "Pages.Administration.Settings.AppSettings",
                label: "App Settings",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.AppSettings",
                children:[
                  {
                    key: "Pages.Administration.Settings.AppSettings.SystemAdmin",
                    label: "System Admin",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Settings.AppSettings.SystemAdmin"
                  }, {
                    key: "Pages.Administration.Settings.AppSettings.CronSettings",
                    label: "Cron Settings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Settings.AppSettings.CronSettings"
                  }, {
                    key: "Pages.Administration.Settings.AppSettings.SubordinateLevel",
                    label: "Subordinate Level",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Settings.AppSettings.SubordinateLevel"
                  }, {
                    key: "Pages.Administration.Settings.AppSettings.SystemTimeZone",
                    label: "System Time Zone",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Settings.AppSettings.SystemTimeZone"
                  },
    
                ]
              },
              {
                key: "Pages.Administration.Settings.AuditLog",
                label: "Audit Log",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.AuditLog",
              },
              {
                key: "Pages.Administration.Settings.CronJobData",
                label: "CronJob Data",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.CronJobData",
              },
              {
                key: "Pages.Administration.Settings.CronJobStatus",
                label: "CronJob Status",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.CronJobStatus",
              },
            ],
          },
        ],
      },
      {
        key: "Pages.Dashboard",
        label: "Dashboard",
        data: "Pages.Dashboard",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.Dashboard.TopBillingEmployee",
            label: "Top Billing Employee",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.TopBillingEmployee",
          },
          {
            key: "Pages.Dashboard.EmployeePerformance",
            label: "Employee Performance",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.EmployeePerformance",
          },
          {
            key: "Pages.Dashboard.EmployeeSkills",
            label: "Employee Skills",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.EmployeeSkills",
          },
          {
            key: "Pages.Dashboard.TopBillingProjects",
            label: "Top Billing Projects",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.TopBillingProjects",
          },
          {
            key: "Pages.Dashboard.TopTrackingProjects",
            label: "Top Tracking Projects",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.TopTrackingProjects",
          },{
            key: "Pages.Dashboard.YourPerformanceChart",
            label: "Your Performance Chart",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.YourPerformanceChart",
          }
        ],
      }
     ,
      {
        key: "Pages.Project",
        label: "Project",
        data: "Pages.Project",
        icon: "pi pi-fw pi-file",
      },
      {
        key: "Pages.MyTeam",
        label: "My Team",
        data: "Pages.MyTeam",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.MyTeam.Skills",
            label: "Skills",
            icon: "pi pi-fw pi-file",
            data: "Pages.MyTeam.Skills",
            children: [
              {
                key: "Pages.MyTeam.Skills.AssignSkills",
                label: "Assign Skills",
                icon: "pi pi-fw pi-file",
                data: "Pages.MyTeam.Skills.AssignSkills",
              },
            ],
          },
        ],
      },
      {
        key: "Pages.MyPerformance",
        label: "My Performance",
        data: "Pages.MyPerformance",
        icon: "pi pi-fw pi-file",
      },
     
      {
        key: "Pages.EmployeeBilling",
        label: "Employee Billing",
        data: "Pages.EmployeeBilling",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.EmployeeBilling.UpdateEmployeeBillingTime",
            label: "Update Employee Billing Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.EmployeeBilling.UpdateEmployeeBillingTime",
          },
        ],
      },
      {
        key: "Pages.ProjectBilling",
        label: "Project Billing",
        data: "Pages.ProjectBilling",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.ProjectBilling.AddProjectBillingTime",
            label: "Add Project Billing Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.ProjectBilling.AddProjectBillingTime",
          },
          {
            key: "Pages.ProjectBilling.UpdateProjectBillingTime",
            label: "Update Project Billing Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.ProjectBilling.UpdateProjectBillingTime",
          },
        ],
      },
      {
        key: "Pages.Options",
        label: "Options",
        data: "Pages.Options",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.Options.CreateOption",
            label: "Create Option",
            icon: "pi pi-fw pi-file",
            data: "ages.Administration.Options.CreateOption",
          },
          {
            key: "Pages.Options.EditOption",
            label: "Edit Option",
            icon: "pi pi-fw pi-file",
            data: "Pages.Options.EditOption",
          },
          {
            key: "Pages.Options.DeleteOption",
            label: "Delete Option",
            icon: "pi pi-fw pi-file",
            data: "Pages.Options.DeleteOption",
          },
        ],
      },
      
      {
        key: "Pages.RatingsAndReviews",
        label: "Ratings & Reviews",
        icon: "pi pi-fw pi-file",
        data: "Pages.RatingsAndReviews",
        children: [
          {
            key: "Pages.RatingsAndReviews.Score",
            label: "Score",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Score",
          },
          {
            key: "Pages.RatingsAndReviews.Weightage",
            label: "Weightage",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Weightage",
          },
          {
            key: "Pages.RatingsAndReviews.Target",
            label: "Target",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Target",
          },
          {
            key: "Pages.RatingsAndReviews.Performance",
            label: "Performance",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Review",
          },
          {
            key: "Pages.RatingsAndReviews.Role",
            label: "Role",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Role",
          },
        ],
      },
      {
        key: "Pages.PerformanceReport",
        label: "Performance Report",
        icon: "pi pi-fw pi-file",
        data: "Pages.PerformanceReport",
      },
      {
        key: "Pages.Report",
        label: "Report",
        icon: "pi pi-fw pi-file",
        data: "Pages.Report",
        children: [
          {
            key: "Pages.Report.ProjectBillableTime",
            label: "Project Billable Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.ProjectBillableTime",
          },
          {
            key: "Pages.Report.ProjectTrackableTime",
            label: "Project Trackable Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.ProjectTrackableTime",
          },
          {
            key: "Pages.Report.TotalTrackAndBillTimeOfProjects",
            label: "Total Track And Bill Time of Projects",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.TotalTrackAndBillTimeOfProjects",
          },
          {
            key: "Pages.Report.EmployeeBillableTime",
            label: "Employee Billable Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.EmployeeBillableTime",
          },
          {
            key: "Pages.Report.EmployeeTrackableTime",
            label: "Employee Trackable Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.EmployeeTrackableTime",
          },
        ],
      },
      {
        key: "Pages.Goals",
        label: "Goals",
        icon: "pi pi-fw pi-file",
        data: "Pages.Goals",
        children: [
          {
            key: "Pages.Goals.CreateGoal",
            label: "Create Goal",
            icon: "pi pi-fw pi-file",
            data: "Pages.Goals.CreateGoal",
          },
          {
            key: "Pages.Goals.EditGoal",
            label: "Edit Goal",
            icon: "pi pi-fw pi-file",
            data: "Pages.Goals.EditGoal",
          },
          {
            key: "Pages.Goals.DeleteGoal",
            label: "Delete Goal",
            icon: "pi pi-fw pi-file",
            data: "Pages.Goals.DeleteGoal",
          },
          {
            key: "Pages.Goals.GoalStep",
            label: "Goal Steps",
            icon: "pi pi-fw pi-file",
            data: "Pages.Goals.GoalStep",
            children: [
              {
                key: "Pages.Goals.GoalStep.CreateGoalStep",
                label: "Create Goal Step",
                icon: "pi pi-fw pi-file",
                data: "Pages.Goals.GoalStep.CreateGoalStep",
              },
              {
                key: "Pages.Goals.GoalStep.EditGoalStep",
                label: "Edit Goal Step",
                icon: "pi pi-fw pi-file",
                data: "Pages.Goals.GoalStep.EditGoalStep",
              },
              {
                key: "Pages.Goals.GoalStep.DeleteGoalStep",
                label: "Delete Goal Step",
                icon: "pi pi-fw pi-file",
                data: "Pages.Goals.GoalStep.DeleteGoalStep",
              },
            ],
          },
         
          
        ],
      },
      {
        key: "Pages.UserGoals",
        label: "User Goals",
        icon: "pi pi-fw pi-file",
        data: "Pages.UserGoals",
      },
      {
        key: "Pages.MyGoals",
        label: "My Goals",
        icon: "pi pi-fw pi-file",
        data: "Pages.MyGoals",
      },
      {
        key: "Pages.Skills",
        label: "Skills",
        icon: "pi pi-fw pi-file",
        data: "Pages.Skills",
        children: [
          {
            key: "Pages.Skills.CreateSkill",
            label: "Create Skill",
            icon: "pi pi-fw pi-file",
            data: "Pages.Skills.CreateSkill",
          },
          {
            key: "Pages.Skills.EditSkill",
            label: "Edit Skill",
            icon: "pi pi-fw pi-file",
            data: "Pages.Skills.EditSkill",
          },
          {
            key: "Pages.Skills.DeleteSkill",
            label: "Delete Skill",
            icon: "pi pi-fw pi-file",
            data: "Pages.Skills.DeleteSkill",
          },
        ],
      }
    ],
  },
];

const treeWithUnCheckedRoles :DisabledTreeNodeDto[] = [
  {
    key: "Pages",
    label: "Pages",
    data: "Pages",
    icon: "pi pi-fw pi-file",
    children: [
      {
        key: "Pages.Administration",
        label: "Administration",
        data: "Pages.Administration",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.Administration.Roles",
            label: "Roles",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.Roles",
            selectable:false,
            children: [
              {
                key: "Pages.Administration.Roles.EditRole",
                label: "Edit Role",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Roles.EditRole",
                selectable:false,
              },
              {
                key: "Pages.Administration.Roles.EditPermission",
                label: "Edit Permission",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Roles.EditPermission",
                selectable:false,
              },
            ],
          },
          {
            key: "Pages.Administration.Users",
            label: "Users",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.Users",
            children: [
              {
                key: "Pages.Administration.Users.EditPermission",
                label: "Edit Permission",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Users.EditPermission",
              },
            ],
          },
          {
            key: "Pages.Administration.KpiMappings",
            label: "Kpi Mappings",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.KpiMappings",
            children: [
              {
                key: "Pages.Administration.KpiMappings.RoleMappings",
                label: "Role Mappings",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.KpiMappings.RoleMappings",
                children: [
                  {
                    key: "Pages.Administration.KpiMappings.RoleMappings.CreateRoleMapping",
                    label: "Create Role Mappings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.KpiMappings.RoleMappings.CreateRoleMapping",
                  },
                  {
                    key: "Pages.Administration.KpiMappings.RoleMappings.EditRoleMapping",
                    label: "Edit Role Mappings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.KpiMappings.RoleMappings.EditRoleMapping",
                  },
                ],
              },
              {
                key: "Pages.Administration.KpiMappings.UserMappings",
                label: "User Mappings",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.KpiMappings.UserMappings",
                children: [
                  {
                    key: "Pages.Administration.KpiMappings.UserMappings.CreateUserMapping",
                    label: "Create User Mappings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.KpiMappings.UserMappings.CreateUserMapping",
                  },
                  {
                    key: "Pages.Administration.KpiMappings.UserMappings.EditUserMapping",
                    label: "Edit User Mappings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.KpiMappings.UserMappings.EditUserMapping",
                  },
                ],
              },
            ],
          },
          {
            key: "Pages.Administration.Kpi",
            label: "Kpi",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.Kpi",
            children: [
              {
                key: "Pages.Administration.Kpi.CreateKpi",
                label: "Create KPI",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Kpi.CreateKpi",
              },
              {
                key: "Pages.Administration.Kpi.EditKpi",
                label: "Edit KPI",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Kpi.EditKpi",
              },
              {
                key: "Pages.Administration.Kpi.DeleteKpi",
                label: "Delete KPI",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Kpi.DeleteKpi",
              },
              {
                key: "Pages.Administration.Kpi.SubKpi",
                label: "Sub-KPI",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Kpi.SubKpi",
                children: [
                  {
                    key: "Pages.Administration.Kpi.SubKpi.CreateSubKpi",
                    label: "Create Sub-KPI",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Kpi.SubKpi.CreateSubKpi",
                  },
                  {
                    key: "Pages.Administration.Kpi.SubKpi.DeleteSubKpi",
                    label: "Delete Sub-KPI",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Kpi.SubKpi.DeleteSubKpi",
                  },
                ],
              },
            ],
          },
          {
            key: "Pages.Administration.Settings",
            label: "Settings",
            icon: "pi pi-fw pi-file",
            data: "Pages.Administration.Settings",
            children: [
              {
                key: "Pages.Administration.Settings.MailSettings",
                label: "Mail Settings",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.MailSettings",
              },
              {
                key: "Pages.Administration.Settings.MailTemplate",
                label: "Mail Template",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.MailTemplate",
              },
              {
                key: "Pages.Administration.Settings.AppSettings",
                label: "App Settings",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.AppSettings",
                children:[
                  {
                    key: "Pages.Administration.Settings.AppSettings.SystemAdmin",
                    label: "System Admin",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Settings.AppSettings.SystemAdmin"
                  }, {
                    key: "Pages.Administration.Settings.AppSettings.CronSettings",
                    label: "Cron Settings",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Settings.AppSettings.CronSettings"
                  }, {
                    key: "Pages.Administration.Settings.AppSettings.SubordinateLevel",
                    label: "Subordinate Level",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Settings.AppSettings.SubordinateLevel"
                  }, {
                    key: "Pages.Administration.Settings.AppSettings.SystemTimeZone",
                    label: "System Time Zone",
                    icon: "pi pi-fw pi-file",
                    data: "Pages.Administration.Settings.AppSettings.SystemTimeZone"
                  },
    
                ]
              },
              {
                key: "Pages.Administration.Settings.AuditLog",
                label: "Audit Log",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.AuditLog",
              },
              {
                key: "Pages.Administration.Settings.CronJobData",
                label: "CronJob Data",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.CronJobData",
              },
              {
                key: "Pages.Administration.Settings.CronJobStatus",
                label: "CronJob Status",
                icon: "pi pi-fw pi-file",
                data: "Pages.Administration.Settings.CronJobStatus",
              },
            ],
          },
        ],
      },
      {
        key: "Pages.Dashboard",
        label: "Dashboard",
        data: "Pages.Dashboard",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.Dashboard.TopBillingEmployee",
            label: "Top Billing Employee",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.TopBillingEmployee",
          },
          {
            key: "Pages.Dashboard.EmployeePerformance",
            label: "Employee Performance",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.EmployeePerformance",
          },
          {
            key: "Pages.Dashboard.EmployeeSkills",
            label: "Employee Skills",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.EmployeeSkills",
          },
          {
            key: "Pages.Dashboard.TopBillingProjects",
            label: "Top Billing Projects",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.TopBillingProjects",
          },
          {
            key: "Pages.Dashboard.TopTrackingProjects",
            label: "Top Tracking Projects",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.TopTrackingProjects",
          },{
            key: "Pages.Dashboard.YourPerformanceChart",
            label: "Your Performance Chart",
            icon: "pi pi-fw pi-file",
            data: "Pages.Dashboard.YourPerformanceChart",
          }
        ],
      }
     ,
      {
        key: "Pages.Project",
        label: "Project",
        data: "Pages.Project",
        icon: "pi pi-fw pi-file",
      },
      {
        key: "Pages.MyTeam",
        label: "My Team",
        data: "Pages.MyTeam",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.MyTeam.Skills",
            label: "Skills",
            icon: "pi pi-fw pi-file",
            data: "Pages.MyTeam.Skills",
            children: [
              {
                key: "Pages.MyTeam.Skills.AssignSkills",
                label: "Assign Skills",
                icon: "pi pi-fw pi-file",
                data: "Pages.MyTeam.Skills.AssignSkills",
              },
            ],
          },
        ],
      },
      {
        key: "Pages.MyPerformance",
        label: "My Performance",
        data: "Pages.MyPerformance",
        icon: "pi pi-fw pi-file",
      },
     
      {
        key: "Pages.EmployeeBilling",
        label: "Employee Billing",
        data: "Pages.EmployeeBilling",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.EmployeeBilling.UpdateEmployeeBillingTime",
            label: "Update Employee Billing Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.EmployeeBilling.UpdateEmployeeBillingTime",
          },
        ],
      },
      {
        key: "Pages.ProjectBilling",
        label: "Project Billing",
        data: "Pages.ProjectBilling",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.ProjectBilling.AddProjectBillingTime",
            label: "Add Project Billing Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.ProjectBilling.AddProjectBillingTime",
          },
          {
            key: "Pages.ProjectBilling.UpdateProjectBillingTime",
            label: "Update Project Billing Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.ProjectBilling.UpdateProjectBillingTime",
          },
        ],
      },
      {
        key: "Pages.Options",
        label: "Options",
        data: "Pages.Options",
        icon: "pi pi-fw pi-file",
        children: [
          {
            key: "Pages.Options.CreateOption",
            label: "Create Option",
            icon: "pi pi-fw pi-file",
            data: "ages.Administration.Options.CreateOption",
          },
          {
            key: "Pages.Options.EditOption",
            label: "Edit Option",
            icon: "pi pi-fw pi-file",
            data: "Pages.Options.EditOption",
          },
          {
            key: "Pages.Options.DeleteOption",
            label: "Delete Option",
            icon: "pi pi-fw pi-file",
            data: "Pages.Options.DeleteOption",
          },
        ],
      },
      
      {
        key: "Pages.RatingsAndReviews",
        label: "Ratings & Reviews",
        icon: "pi pi-fw pi-file",
        data: "Pages.RatingsAndReviews",
        children: [
          {
            key: "Pages.RatingsAndReviews.Score",
            label: "Score",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Score",
          },
          {
            key: "Pages.RatingsAndReviews.Weightage",
            label: "Weightage",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Weightage",
          },
          {
            key: "Pages.RatingsAndReviews.Target",
            label: "Target",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Target",
          },
          {
            key: "Pages.RatingsAndReviews.Performance",
            label: "Performance",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Review",
          },
          {
            key: "Pages.RatingsAndReviews.Role",
            label: "Role",
            icon: "pi pi-fw pi-file",
            data: "Pages.RatingsAndReviews.Role",
          },
        ],
      },
      {
        key: "Pages.PerformanceReport",
        label: "Performance Report",
        icon: "pi pi-fw pi-file",
        data: "Pages.PerformanceReport",
      },
      {
        key: "Pages.Report",
        label: "Report",
        icon: "pi pi-fw pi-file",
        data: "Pages.Report",
        children: [
          {
            key: "Pages.Report.ProjectBillableTime",
            label: "Project Billable Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.ProjectBillableTime",
          },
          {
            key: "Pages.Report.ProjectTrackableTime",
            label: "Project Trackable Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.ProjectTrackableTime",
          },
          {
            key: "Pages.Report.TotalTrackAndBillTimeOfProjects",
            label: "Total Track And Bill Time of Projects",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.TotalTrackAndBillTimeOfProjects",
          },
          {
            key: "Pages.Report.EmployeeBillableTime",
            label: "Employee Billable Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.EmployeeBillableTime",
          },
          {
            key: "Pages.Report.EmployeeTrackableTime",
            label: "Employee Trackable Time",
            icon: "pi pi-fw pi-file",
            data: "Pages.Report.EmployeeTrackableTime",
          },
        ],
      },
      {
        key: "Pages.Goals",
        label: "Goals",
        icon: "pi pi-fw pi-file",
        data: "Pages.Goals",
        children: [
          {
            key: "Pages.Goals.CreateGoal",
            label: "Create Goal",
            icon: "pi pi-fw pi-file",
            data: "Pages.Goals.CreateGoal",
          },
          {
            key: "Pages.Goals.EditGoal",
            label: "Edit Goal",
            icon: "pi pi-fw pi-file",
            data: "Pages.Goals.EditGoal",
          },
          {
            key: "Pages.Goals.DeleteGoal",
            label: "Delete Goal",
            icon: "pi pi-fw pi-file",
            data: "Pages.Goals.DeleteGoal",
          },
          {
            key: "Pages.Goals.GoalStep",
            label: "Goal Steps",
            icon: "pi pi-fw pi-file",
            data: "Pages.Goals.GoalStep",
            children: [
              {
                key: "Pages.Goals.GoalStep.CreateGoalStep",
                label: "Create Goal Step",
                icon: "pi pi-fw pi-file",
                data: "Pages.Goals.GoalStep.CreateGoalStep",
              },
              {
                key: "Pages.Goals.GoalStep.EditGoalStep",
                label: "Edit Goal Step",
                icon: "pi pi-fw pi-file",
                data: "Pages.Goals.GoalStep.EditGoalStep",
              },
              {
                key: "Pages.Goals.GoalStep.DeleteGoalStep",
                label: "Delete Goal Step",
                icon: "pi pi-fw pi-file",
                data: "Pages.Goals.GoalStep.DeleteGoalStep",
              },
            ],
          },
         
          
        ],
      },
      {
        key: "Pages.UserGoals",
        label: "User Goals",
        icon: "pi pi-fw pi-file",
        data: "Pages.UserGoals",
      },
      {
        key: "Pages.MyGoals",
        label: "My Goals",
        icon: "pi pi-fw pi-file",
        data: "Pages.MyGoals",
      },
      {
        key: "Pages.Skills",
        label: "Skills",
        icon: "pi pi-fw pi-file",
        data: "Pages.Skills",
        children: [
          {
            key: "Pages.Skills.CreateSkill",
            label: "Create Skill",
            icon: "pi pi-fw pi-file",
            data: "Pages.Skills.CreateSkill",
          },
          {
            key: "Pages.Skills.EditSkill",
            label: "Edit Skill",
            icon: "pi pi-fw pi-file",
            data: "Pages.Skills.EditSkill",
          },
          {
            key: "Pages.Skills.DeleteSkill",
            label: "Delete Skill",
            icon: "pi pi-fw pi-file",
            data: "Pages.Skills.DeleteSkill",
          },
        ],
      }
    ],
  },
];

export  {treeDataWithoutSelectable,treeDataWithSelectable,treeWithUnCheckedRoles};

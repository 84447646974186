import { get } from "../../utills/BaseService";


export async function getAllRole(dispatch: any) {
   return await get(dispatch, '/role/getAllRole', null)
}
export async function getAllFilterRoles(dispatch: any) {
   return await get(dispatch, '/role/getAllFilterRoles', null)
}
export async function getAllRoleInTimerAndRolesTable(dispatch: any) {
   return await get(dispatch, '/role/getAllRolesForMapping', null)
}
import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import themeSlice from "./slices/themeSlice";
import sidebarSlice from "./slices/sidebarSlice";
import localizationSlice from "./slices/localizationSlice";
import errorSlice from "./slices/errorSlice";
import loadingSlice from "./slices/loadingSlice";
import loginSlice from "./slices/loginSlice";
import profilePicSlice from "./slices/profilePicSlice";
import routePathSlice from "./slices/routePathSlice";
import performanceViewInput from "./slices/performanceViewInput";
import permissionSlice from "./slices/permissionSlice";
import toastSlice from './slices/toastSlice'

export const store = configureStore({
    reducer: {
        user: userSlice,
        theme: themeSlice,
        sidebar: sidebarSlice,
        localization: localizationSlice,
        error: errorSlice,
        loading: loadingSlice,
        login: loginSlice,
        profPic: profilePicSlice,
        routePath: routePathSlice,
        performanceViewInput: performanceViewInput,
        permission:permissionSlice,
        toastError:toastSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})
import { Button } from "primereact/button";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import "../../css/components/permissionDenied.css";
import { useSelector } from "react-redux";
import { Themes } from "../../data/Themes";
import { ThemeState } from "../../dtos/common/ThemeState";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";

export const PermissionDenied = () => {
  const navigate = useNavigate();
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const userPermissions = useSelector((state: any) => state.permission.permissions);
  const handleBackTo = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div 
    className="error-container"
    style={{
      color: mode?.color,
      backgroundColor: mode?.backgroundSecondaryColor
    }}
    >
      <p
        className="err-header"
      
      >
        Sorry,you don't have permissions to access this {userPermissions.length!==0?"page":"application"}
      </p>
      <div className="unhandled-err-btn">
        <Button
          label="Refresh"
          onClick={handleRefresh}
          style={{ backgroundColor: ThemeColors?.primaryColor, border: "none" }}
        />
        <Button
          label="Back to login"
          onClick={handleBackTo}
          style={{ backgroundColor: ThemeColors?.primaryColor, border: "none" }}
        />
      </div>
    </div>
  );
};

export const GetPaginationRowsCount = () => {
    const height = window.innerHeight;
    
    if (height > 799 && height <= 1000) {
        return {
            rowCount: 20,
            rowList: [10, 15, 20, 30]
        };
    }
    if (height > 999 && height <= 2160) {
        return {
            rowCount: 40,
            rowList: [10, 40, 15, 20, 30]
        };
    }
    else if (height > 2160 && height <= 3000) {
        return {
            rowCount: 45,
            rowList: [40, 45, 10, 20, 50, 60]
        };
    }
    else if (height > 3000 && height <= 4000) {
        return {
            rowCount: 50,
            rowList: [50, 10, 20, 55, 60, 65]
        };
    }
    else if (height > 4000 && height <= 5000) {
        return {
            rowCount: 60,
            rowList: [60, 10, 20, 50, 30]
        };
    }
    return {
        rowCount: 5,
        rowList: [5, 10, 15, 20, 30]
    };
}


import instance, { del, get, patch, post } from "../../utills/BaseService";

export async function createPermissionAssignByRoleName(dispatch: any, input: any): Promise<any> {
   return await post(dispatch, '/permission/createPermissionByRoleName', input);
};
export async function createPermissionAssignByEmployeeCode(dispatch: any, input: any): Promise<any> {
   return await post(dispatch, '/permission/createPermissionByEmployeeCode', input);
};

export async function getPermissionByRoleName(dispatch:any,input:{roleName:string}):Promise<any[]>{
    return await get(dispatch, '/permission/getPermission', input);
}
export async function getSpecificPermission(dispatch:any):Promise<any[]>{
    return await get(dispatch, '/permission/getSpecificPermission', null);
}

export async function checkPermissionExistance(dispatch:any,input:{roleName:string}):Promise<boolean>{
    return await get(dispatch, '/permission/checkPermission', input);
 }
export async function getPermissionByEmployeeCode(dispatch:any,input:{empCode:string,roleName:string}|{roleName:string}):Promise<any[]>{
    return await get(dispatch, '/permission/getPermissionByEmployeeCode', input);
}







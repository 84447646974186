import * as React from "react";
import "../../css/components/upperNavbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronDown,
  faPowerOff,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "primereact/dropdown";
import { Avatar } from "primereact/avatar";
import { InputSwitch } from "primereact/inputswitch";
import { Link, useNavigate } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import { useDispatch, useSelector } from "react-redux";
import { ThemeState } from "../../dtos/common/ThemeState";
import { ThemeButton } from "../subComponents/ThemeButton";
import { changeMode, changeTheme } from "../../store/slices/themeSlice";
import { Themes } from "../../data/Themes";
import { setSideBar, setSideBarClicked } from "../../store/slices/sidebarSlice";
import { SideBarState } from "../../dtos/sidebar/SideBarState";
import { setLocalization } from "../../store/slices/localizationSlice";
import axios from "axios";
import {
  getAllAppSettings,
  setSessionLanguage,
  updateSettings,
  getAllLanguages,
} from "../../service/common/commonService";
import { SessionLanguage } from "../../dtos/common/SettingsLanguage";
import { memo, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { changePwd, logout } from "../../service/user/userService";
import { Toast } from "primereact/toast";
import lock from "../../images/lock.png";
import { ProgressSpinner } from "primereact/progressspinner";
import { LoggedInUserData } from "../../shared/functions/LoggedInUserData";
import { IN, US } from "country-flag-icons/react/3x2";
import { setProfilePic } from "../../store/slices/profilePicSlice";
import { getProfilePicFromTracker } from "../../shared/functions/GetProfilePicFromTracker";
import {
  createTheme,
  getUserTheme,
} from "../../service/userThemes/userThemeService";

const UpperNavbar = () => {
  const lastSetLanguage = localStorage.getItem("Current-Language");
  const lastSetMode = localStorage.getItem("Mode");
  const [userName, setUserName] = useState<string>("");
  const profilePic = useSelector((state: any) => state.profPic.profilePic);
  const [language, setLanguage] = React.useState(null);
  const [allLanguage, setAllLanguage] = React.useState<any[]>([]);
  const [checked, setChecked] = React.useState<any>(false);
  const navigate = useNavigate();
  const op = React.useRef<any>(null);
  const dispatch = useDispatch();
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const localization = useSelector((state: any) => state.localization);
  const [changePassword, setChangePassword] = React.useState(false);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const [oldPwd, setOldPwd] = React.useState<string>("");
  const [newPwd, setNewPwd] = React.useState<string>("");
  const [confirmNewPwd, setConfirmNewPwd] = React.useState<string>("");
  const toast = useRef<any>(null);
  const [pwdError, setPwdError] = React.useState(false);
  const validPassword = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$");
  const languageOptions = allLanguage.map((lan) => ({
    label: (
      <div className="flag-container">
        <span>{lan.DisplayName}</span>
        {lan.DisplayName.toLowerCase() !== "english" ? (
          <IN title="India" className="your-class-name" width={30} />
        ) : (
          <US title="USA" width={30} />
        )}
      </div>
    ),
    value: lan,
  }));

  //#endregion

  //#region all functions

  const getAllLanguage = async () => {
    const languages = await getAllLanguages(dispatch);

    if (languages) {
      setAllLanguage(languages);
    }

    if (lastSetLanguage) {
      const parsedLanguage = JSON.parse(lastSetLanguage);
      const foundLanguage = languages.find(
        (language: { Name: string; DisplayName: string }) =>
          language.Name === parsedLanguage.name
      );
      const englishLanguage =
        foundLanguage ||
        languages.find(
          (language: { Name: string; DisplayName: string }) =>
            language.Name === "en"
        );
      if (foundLanguage) {
        handleLanguage({ value: foundLanguage });
      } else {
        handleLanguage({ value: englishLanguage });
      }
    }
    const user: any = await LoggedInUserData(dispatch);
    setUserName(user?.UserName);
    const input = {
      EmployeeCode: user?.EmployeeCode,
      EmployeeID: user.EmployeeID,
    };
    const profilePicture: any = await getProfilePicFromTracker(dispatch, input);
    dispatch(setProfilePic(profilePicture));
  };

  const isLoading = useSelector((state: any) => state.loading.isloading);
  const isClicked = useSelector(
    (state: SideBarState) => state.sidebar.isClicked
  );

  axios.defaults.withCredentials = true;

  const handleLanguage = async (e: any) => {
    if (e?.target) {
      e.preventDefault();
    }

    setLanguage(e.value);
    const credentials: SessionLanguage = {
      name: e.value.Name,
    };
    await setSessionLanguage(dispatch, credentials);
    localStorage.setItem("Current-Language", JSON.stringify(credentials));
    const localization = await getAllAppSettings(dispatch);
    dispatch(setLocalization(localization?.values));
  };

  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      logout();
      navigate("/login");
    }, 500);
  };

  const handleDarkMode = async (e: { value: any }) => {
    setChecked(e.value);
    const userInfo = await LoggedInUserData(dispatch);

    if (e.value) {
      const themeInput = {
        Theme: themeName,
        Mode: "dark",
        User: userInfo.EmployeeCode,
      };
      await createTheme(dispatch, themeInput);
      dispatch(
        changeMode({
          appMode: "dark",
          themeName: "",
        })
      );
    } else {
      const themeInput = {
        Theme: themeName,
        Mode: "light",
        User: userInfo.EmployeeCode,
      };
      await createTheme(dispatch, themeInput);
      dispatch(
        changeMode({
          appMode: "light",
          themeName: "",
        })
      );
    }
  };

  const isOpen = useSelector((state: SideBarState) => state.sidebar.isOpen);

  const handleSideBar = () => {
    const screenWidth = window.innerWidth;
    const shouldOpenSidebar = screenWidth < 1200;
    if (!shouldOpenSidebar) {
      if (isOpen) {
        dispatch(setSideBar(false));
        dispatch(setSideBarClicked(true));
      } else {
        dispatch(setSideBar(true));
        dispatch(setSideBarClicked(false));
      }
    } else {
      if (isOpen) {
        dispatch(setSideBar(!isOpen));
        dispatch(setSideBarClicked(isClicked));
      }
    }
  };

  const handleChangePassword = async (e: any) => {
    e.preventDefault();
    if (
      oldPwd.length === 0 &&
      newPwd.length === 0 &&
      confirmNewPwd.length === 0
    ) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please complete all the field",
        life: 3000,
      });
    } else {
      if (!validPassword.test(newPwd)) {
        setPwdError(true);
      } else {
        const loggedInUser: string | null = localStorage.getItem("user");
        const userData = JSON.parse(loggedInUser as string);

        const inputs = {
          email: userData.Email,
          oldPassword: oldPwd,
          newPassword: newPwd,
          confirmNewPassword: confirmNewPwd,
        };
        await changePwd(dispatch, inputs);
        setOldPwd("");
        setNewPwd("");
        setConfirmNewPwd("");
        setChangePassword(false);
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  const hideChangePwd = () => {
    setChangePassword(false);
    setOldPwd("");
    setNewPwd("");
    setConfirmNewPwd("");
  };

  //#endregion

  //#region all templates

  const changePwdHeader = <img src={lock} className="lock" />;

  const changePwdFooter = (
    <ul className="pl-2 ml-2 mt-0 line-height-3">
      <li>At least one lowercase</li>
      <li>At least one uppercase</li>
      <li>At least one numeric</li>
      <li>Minimum 8 characters</li>
    </ul>
  );

  //#endregion

  const getTheme = async () => {
    const userInfo = await LoggedInUserData(dispatch);
    const theme = await getUserTheme(dispatch, {
      empCode: userInfo.EmployeeCode,
    });
    if (theme && theme.Theme) {
      dispatch(changeTheme({ themeName: theme.Theme, appMode: "" }));
    }else{
      dispatch(changeTheme({ themeName, appMode: "" }));
    }

    if (theme && theme.Mode) {
      dispatch(changeMode({ appMode: theme.Mode, themeName: "" }));
      theme.Mode==="dark"?setChecked(true):setChecked(false)
    }else{
      dispatch(changeMode({ appMode: modeName, themeName: "" }));
      setChecked(false)
    }
  };

  useEffect(() => {
    getAllLanguage();
    getTheme();
    // if (lastSetMode === "dark") {
    //   handleDarkMode({ value: true });
    // }else{
    //   handleDarkMode({ value: false });
    // }
  }, []);

  return (
    <div
      className="upper-navbar"
      style={{ backgroundColor: ThemeColors?.secondaryColor }}
    >
      <Toast ref={toast} />

      {/* left side ****************/}

      <div className="left-upper-navbar">
        <span className="loading-bar">
          {isLoading ? <ProgressSpinner /> : ""}
        </span>

        <FontAwesomeIcon
          icon={faBars}
          className="upper-navbar-icon-bar"
          size="xl"
          onClick={handleSideBar}
        />
      </div>

      {/* right side ****************/}

      <div className="right-upper-navbar">
        <div className="upper-navbar-language">
          <Dropdown
            className="upper-navbar-language-dropdown"
            options={languageOptions}
            optionLabel="label"
            value={language}
            onChange={handleLanguage}
            placeholder="English"
            panelStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
          />
        </div>
        <div className="upper-navbar-role-name">
          <button
            onClick={(e) => op?.current?.toggle(e)}
            className="upper-navbar-user"
          >
            {userName}
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <OverlayPanel
            ref={op}
            style={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
          >
            <div className="user-dropdown-container">
              <button style={{ color: mode?.color }}>
                <FontAwesomeIcon icon={faUser} style={{ color: mode?.color }} />
                <Link
                  to="/editProfile"
                  style={{ color: mode?.color, textDecoration: "none" }}
                >
                  {localization?.localization?.Profile || "Profile"}
                </Link>
              </button>
              <button style={{ color: mode?.color }} onClick={handleLogout}>
                <FontAwesomeIcon
                  icon={faPowerOff}
                  style={{ color: mode?.color }}
                />
                {localization?.localization?.Logout || "Logout"}
              </button>
              <hr />
              <div className="theme-colors">
                <ThemeButton themeName="violet" buttonColor="#551fb3" />
                <ThemeButton themeName="green" buttonColor="#407053" />
                <ThemeButton themeName="maroon" buttonColor="#652524" />
              </div>
              <div className="upper-navbar-changemode-switch-button-div">
                {localization?.localization?.Light || "Light"}{" "}
                <InputSwitch
                  checked={checked}
                  onChange={handleDarkMode}
                  className="upper-navbar-changemode-switch-button"
                />{" "}
                {localization?.localization?.Dark || "Dark"}
              </div>
            </div>
          </OverlayPanel>
        </div>
        <div className="upper-navbar-user-image">
          <Avatar
            image={
              profilePic ||
              "https://png.pngtree.com/element_our/png/20181206/users-vector-icon-png_260862.jpg"
            }
            className="upper-navbar-avatar"
            shape="circle"
            onClick={(e) => op?.current?.toggle(e)}
          />
        </div>
      </div>

      {/* dialog for change password ************************/}

      <Dialog
        header={changePwdHeader}
        style={{
          width: "22rem",
          backgroundColor: mode?.backgroundPrimaryColor,
          color: mode?.color,
        }}
        position="top"
        visible={changePassword}
        onHide={hideChangePwd}
      >
        <form className="change-pwd" onSubmit={handleChangePassword}>
          <div>
            <label style={{ color: mode?.color }}>Old Password</label>
            <Password
              value={oldPwd}
              onChange={(e) => setOldPwd(e.target.value)}
              inputStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              inputClassName="chang-pwd-input"
              toggleMask
            />
          </div>
          <div>
            <label style={{ color: mode?.color }}>New Password </label>
            <Password
              value={newPwd}
              onChange={(e) => setNewPwd(e.target.value)}
              footer={changePwdFooter}
              inputStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              inputClassName="chang-pwd-input"
              toggleMask
            />
            {pwdError && <span className="err-msg">Password is invalid</span>}
          </div>
          <div>
            <label style={{ color: mode?.color }}>Confirm New Password</label>
            <Password
              value={confirmNewPwd}
              onChange={(e) => setConfirmNewPwd(e.target.value)}
              inputClassName="chang-pwd-input"
              inputStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              toggleMask
            />
          </div>
          <div>
            <Button
              type="submit"
              label="Reset Password"
              style={{
                backgroundColor: ThemeColors?.primaryColor,
                color: ThemeColors?.fontColor,
              }}
              className="change-pwd-btn"
            />
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default UpperNavbar;

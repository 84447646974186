import { createSlice } from "@reduxjs/toolkit";

interface State {
    isError: boolean,
    errorMessage: string
}
interface Action {
    type: string,
    payload: State
}

const toastSlice = createSlice({
    name: "ToastSlice",
    initialState: {
        isError: false,
        errorMessage: ''
    },
    reducers: {
        setError(state: State, action: Action) {
            state.isError = action.payload.isError
            state.errorMessage = action.payload.errorMessage
        }
    }
});

export const { setError } = toastSlice.actions;
export default toastSlice.reducer;
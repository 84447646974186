import { AllProjectInputDto } from "../../dtos/project/AllProjectInputDto";
import { get } from "../../utills/BaseService";

export async function getProjectByUser(dispatch: any,input:any) {
  return await get(dispatch, "/project/getProjectByUser", input);
}

export async function getAllSourceTypeForproject(dispatch: any) {
  return await get(dispatch, "/project/getAllSourceType", null);
}

export async function getAllProject(dispatch: any, input: AllProjectInputDto) {
  return await get(dispatch, "/project/getAllProject", input);
}

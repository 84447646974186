import { Toolbar } from "primereact/toolbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { getSubordinatesByStatus } from "../../service/employee/employeeService";
import { EmployeeDto } from "../../dtos/employee/EmployeeDto";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
  faCheck,
  faCircleMinus,
  faMinus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import AssignSkillDialog from "./AssignSkillDialog";
import { Chip } from "primereact/chip";
import "../../css/components/goalTable.css";
import { Dialog } from "primereact/dialog";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { Toast } from "primereact/toast";
import {
  assignSkills,
  createSkillInfo,
  myTeamSkills,
  skills,
} from "../../shared/constant/PermissionVariables";
import { isPermissionGiven } from "../../shared/functions/HasPermission";
import { GetPaginationRowsCount } from "../../shared/functions/GetPaginationRowsCount";
import { GetPaginationRowsInterface } from "../../dtos/common/GetPaginationRows";
import {
  deleteSkillByEmpCodeAndSkillName,
  getSkillByEmpCode,
} from "../../service/userSkill/userSkillServices";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export interface LazyState {
  page: number;
  first: number;
  rows: number;
  sortField: string;
  sortOrder: number;
  filters?: string;
}
export const Employee = () => {
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  const [employee, setEmployee] = useState<EmployeeDto[]>([]);
  const dt = React.useRef<any>(null);
  const [searchText, setSearchText] = useState<string>("");
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const statusOptions = [
    { name: localization?.Active || "Active", value: "active" },
    { name: localization?.InActive || "Inactive", value: "inactive" },
    ,
    { name: localization?.All || "All", value: "all" },
  ];
  const [statusValue, setStatusValue] = useState(statusOptions[0]?.value);
  const [showAssignSkills, setShowAssignSkills] = useState<boolean>(false);
  const [showSkillDialog, setShowSkillDialog] = useState<boolean>(false);
  const [clickedShowSkillDialog, setClickedShowSkillDialog] = useState<
    number | null
  >(null);
  const [totalSubordinates, setTotalSubordinates] = useState<number>(0);
  const toast = React.useRef(null) as any;
  const row: GetPaginationRowsInterface = GetPaginationRowsCount();
  const [lazyState, setlazyState] = React.useState({
    page: 0,
    first: 0,
    rows: row.rowCount,
    sortField: "createdAt",
    sortOrder: 0 as any,
  });
  const [skillAfterDeletion, setSkillAfterDeletion] = useState<any[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<any>();
  const userPermissions = useSelector(
    (state: any) => state.permission.permissions
  );
  //#region all Functions

  const getAllEmp = async () => {
    const result: { subordinates: EmployeeDto[]; totalResult: number } =
      await getSubordinatesByStatus(dispatch, {
        Status: statusValue as string,
        Limit: lazyState.rows,
        Page: lazyState.page,
        SearchValue: searchText,
      });
    const filteredEmp = result?.subordinates?.filter((emp) => {
      return (
        emp?.fullName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        emp?.emailAddress?.toLowerCase().includes(searchText.toLowerCase()) ||
        emp?.employeeCode?.toLowerCase().startsWith(searchText?.toLowerCase())
      );
    });
    setTotalSubordinates(result.totalResult);
    setEmployee(filteredEmp);
  };

  const deleteSkillByEmpcode = async (rowData: any, skillName: string) => {
    const inputForDeleteOp = {
      EmployeeCode: rowData.employeeCode as string,
      SkillName: skillName,
    };
    await deleteSkillByEmpCodeAndSkillName(dispatch, inputForDeleteOp);
    const notDeletedSkills = await getSkillByEmpCode(dispatch, {
      EmployeeCode: rowData.employeeCode,
    });
    setSkillAfterDeletion(notDeletedSkills);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Skill deleted successfully for this employee",
      life: 3000,
    });
    getAllEmp();
  };



  const handleStatusValue = (e: any) => {
    setStatusValue((prev) => {
      if (e.value === null) {
        return prev;
      } else {
        return e.value;
      }
    });
  };

  const accept = (rowData:any,skillName:string) => {
    deleteSkillByEmpcode(rowData, skillName)
    
}

const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
}

  const confirm2 = (rowData:any,skillName:string) => {
    showSkillsDialogHandler(false)
    confirmDialog({
        message: 'Do you want to delete this skill?',
        header: 'Delete Confirmation',
        acceptClassName: 'p-button-danger',
        accept:()=>accept(rowData,skillName),
        reject
    });
};

  const showSkillsDialogHandler = (rowData: any) => {
    setShowSkillDialog(true);
    setClickedShowSkillDialog(rowData.id);
  };

  const showAssignSkillDialog = () => {
    const hasPermission = isPermissionGiven(
      assignSkills,
      userPermissions,
      dispatch
    );
    if (!hasPermission) {
      return;
    }
    if (!userPermissions.includes(createSkillInfo)) {
      return toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "Sorry,you don't have create skill permission,So ypu can't access this feature ",
        life: 3000,
      });
    }
    setShowAssignSkills(true);
  };

  //#endregion

  //#region all Templates

  const startToolbar = () => {
    return (
      <div>
        <h4>{localization?.Employee || "Employee"}</h4>
        <p>
          {localization?.ViewEmployeeInformation || "View employee information"}
        </p>
      </div>
    );
  };

  const skillTemplate = (rowData: any) => {
    return (
      <div>
        <Avatar
          className="p-overlay-badge avatar-content skill-icon skill-avatar"
          onClick={() => {
            rowData?.skills?.length > 0 && showSkillsDialogHandler(rowData);
          }}
          style={{ cursor: rowData?.skills?.length ? "pointer" : "default" }}
        >
          <FontAwesomeIcon
            icon={faAward}
            style={{
              fontSize: "22px",
              color: rowData.skills.length > 0 ? mode?.color : "#817d7d",
            }}
          />
          {rowData?.skills?.length > 0 && (
            <Badge value={rowData?.skills?.length || 0} />
          )}
        </Avatar>

        {showSkillDialog && clickedShowSkillDialog === rowData.id && (
          <Dialog
            header={localization?.Skills || "Skills"}
            visible={showSkillDialog}
            style={{
              width: "30vw",
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            contentStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            onHide={() => {
              if (!showSkillDialog) return;
              setShowSkillDialog(false);
              setClickedShowSkillDialog(null);
              setSkillAfterDeletion([])
            }}
          >
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {(() => {
                const skillsToDisplay =
                  Array.isArray(skillAfterDeletion) &&
                  skillAfterDeletion.length > 0
                    ? skillAfterDeletion
                    : Array.isArray(rowData.skills) && rowData.skills.length > 0
                    ? rowData.skills
                    : null;

                return skillsToDisplay
                  ? skillsToDisplay.map(
                      (skill: { SkillName: string; GoalName: string }) => (
                        <div
                          className="avatar-container"
                          style={{
                            marginRight: "12px",
                            marginTop:'12px',
                            position: "relative",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faAward}
                            className="skill-icon"
                            style={{
                              color: skill.GoalName ? "blue" : "#25ce25",
                              fontSize: "24px",
                            }}
                          />
                          <p className="skill-text">{skill.SkillName}</p>
                          {/* Add the Minus icon */}
                          <FontAwesomeIcon
                            icon={faCircleMinus}
                            style={{
                              position: "absolute",
                              top: "-8px", // Top-right corner
                              right: "12px",
                              color: "#B43235",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              confirm2(rowData, skill.SkillName)
                              
                            }
                          />
                        </div>
                      )
                    )
                  : localization?.NoSkillsAvailableForThisEmployee ||
                      "No skills available for this employee";
              })()}
            </div>
          </Dialog>
        )}
      </div>
    );
  };
  const endToolbar = () => {
    return (
      <div className="endToolbarPerformance">
        {employee.length > 0 && userPermissions.includes(assignSkills) && (
          <Button
            onClick={() => showAssignSkillDialog()}
            style={{ fontWeight: "bold" }}
          >
            {localization?.AssignSkills || "Assign Skills"}
          </Button>
        )}
        <SelectButton
          value={statusValue}
          onChange={(e) => handleStatusValue(e)}
          options={statusOptions}
          optionLabel="name"
        />
        <InputText
          type="search"
          placeholder={localization?.Search || "Search"}
          onChange={(e) => {
            setTimeout(() => {
              setSearchText(e.target.value);
            }, 500);
          }}
        />
      </div>
    );
  };

  const activeBodyTemplate = (rowData: any) => {
    return (
      <Tag
        className={rowData?.isActive === true ? "activeIcon" : "inactiveicon"}
        value={
          rowData?.isActive === true ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            <FontAwesomeIcon icon={faXmark} />
          )
        }
        rounded
      ></Tag>
    );
  };

  const empRoleTemplate = (data: EmployeeDto) => {
    const { roleNames } = data;

    if (roleNames && roleNames.length > 1) {
      // If there are multiple roles, join them with commas
      const roles = roleNames.join(", ");
      return <span>{roles}</span>;
    } else if (roleNames && roleNames.length === 1) {
      // If there is only one role, display it without a comma
      return <span>{roleNames[0]}</span>;
    } else {
      // Handle the case when there are no roles
      return <span>No Role</span>;
    }
  };

  const onPageChange = (event: any) => {
    setlazyState(event);
  };

  const onSort = (event: any) => {
    setlazyState((prev: LazyState) => {
      const newState: LazyState = {
        ...prev, // Copy all properties from the previous state
        sortOrder: event.sortOrder, // Update sortOrder
        sortField: event.sortField, // Update sortField
      };
      return newState;
    });
  };

  //#endregion

  useEffect(() => {
    getAllEmp();
  }, [searchText, statusValue, lazyState]);

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Toolbar
        start={startToolbar}
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="mb-3"
      />
      <Card
        style={{ backgroundColor: mode?.backgroundSecondaryColor }}
        className="empolyee_table"
      >
        <DataTable
          ref={dt}
          dataKey="id"
          value={employee}
          selection={selectedEmployee}
          onSelectionChange={(e: any) => setSelectedEmployee(e.value)}
          paginator
          first={lazyState.first}
          rows={lazyState.rows}
          totalRecords={totalSubordinates}
          onSort={onSort}
          onPage={onPageChange}
          sortOrder={lazyState.sortOrder}
          sortField={lazyState.sortField}
          showGridlines
          lazy
          rowsPerPageOptions={row.rowList}
          currentPageReportTemplate="Total Records {totalRecords}"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          <Column
            field="fullName"
            sortable
            header="Employee Name"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            className="Name_code"
          ></Column>
          <Column
            field="employeeCode"
            sortable
            header="Employee Code"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            className="emp_code"
          ></Column>
          <Column
            className="wrap_all"
            field="emailAddress"
            sortable
            header="Email ID"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
          ></Column>
          <Column
            body={empRoleTemplate}
            header="Role"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            className="emp_code"
          ></Column>
          {userPermissions.includes(myTeamSkills) && (
            <Column
              className="kpiColumn col_center col_150"
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                textAlign: "center",
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              field="skills"
              header={`${localization?.Skills || "Skills"}`}
              body={skillTemplate}
            ></Column>
          )}
          <Column
            body={activeBodyTemplate}
            header="IsActive"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
              textAlign: "center",
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            className="emp_code emp_code_last"
          ></Column>
        </DataTable>
      </Card>
      <AssignSkillDialog
        showAssignSkills={showAssignSkills}
        setShowAssignSkills={setShowAssignSkills}
        getAllEmp={getAllEmp}
      />
    </div>
  );
};

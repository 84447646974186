import { DashboardInputDto } from "../../dtos/dashboard/DashboardInputDto";
import { CreatePerformanceDto } from "../../dtos/performance/CreatePerformanceDto";
import { PerformanceReportInputDto } from "../../dtos/performance/PerformanceReportInputDto";
import { TotalRatingsByKpiInputDto } from "../../dtos/performance/TotalRatingsByKpiInputDto";
import { get, post } from "../../utills/BaseService";

export async function getAllEmployee(dispatch: any) {
    return await get(dispatch, '/performance/getAllEmployee', null);
}

export async function getAllPerformanceWithKPI(dispatch: any,input:any) {
    return await get(dispatch, '/performance/getAllPerformanceWithKPI', input);
}
export async function createPerformance(dispatch: any,body:CreatePerformanceDto) {
    return await post(dispatch, '/performance/createPerformance',body );
}

export async function getPerformanceWithKPIByEmployee(dispatch: any,input:any) {
    return await get(dispatch, '/performance/getPerformanceWithKPIByEmployee', input);
}
export async function getMyPerformance(dispatch: any,input:any) {
    return await get(dispatch, '/performance/getMyPerformance', input);
}
export async function performanceSubmittedOrNot(dispatch: any,input:any) {
    return await get(dispatch, '/performance/performanceSubmittedOrNot', input);
}

export async function getTopPerformedEmployee(dispatch: any,input:DashboardInputDto) {
    return await get(dispatch, '/performance/getTopPerformedEmployee', input);
}

export async function getWorstPerformedEmployee(dispatch: any,input:DashboardInputDto) {
    return await get(dispatch, '/performance/getWorstPerformedEmployee', input);
}

export async function getRatingsByKpiOfLoggedInUser(dispatch: any,input:TotalRatingsByKpiInputDto) {
    return await get(dispatch, '/performance/getRatingsByKpiOfLoggedInUser', input);
}
export async function getUserPerformance(dispatch: any,input:TotalRatingsByKpiInputDto) {
    return await get(dispatch, '/performance/getUserPerformance', input);
}
export async function getPerformanceReport(dispatch: any,input:PerformanceReportInputDto) {
    return await post(dispatch, '/performance/getPerformanceReport', input);
}
export async function getPerformanceReportByKPI(dispatch: any,input:PerformanceReportInputDto) {
    return await post(dispatch, '/performance/getPerformanceReportByKPI', input);
}
export async function getMonthlyRatingsWithKPI(dispatch: any,input:PerformanceReportInputDto) {
    return await post(dispatch, '/performance/getMonthlyRatingsWithKPI', input);
}

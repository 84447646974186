import React, { useEffect } from "react";
import '../../css/components/loader.css'
import { ProgressSpinner } from "primereact/progressspinner";

const Loader = () => {
  return (
    <div
     className="loader-container"
    >
      <ProgressSpinner />
    </div>
  );
};

export default Loader;

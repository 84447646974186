export function isNotUpcomingDate(dateString: string, datetype?: string) {
  const currDate = new Date();
  const inputDate = new Date(dateString);

  if (datetype && datetype === 'week' && inputDate.getDate() < currDate.getDate()) {
    return true
  }
  else if (datetype && datetype === 'year' && currDate.getFullYear() <= inputDate.getFullYear()) {
    if (currDate.getMonth() > 1) {
      return true
    }
    else {
      return false
    }
  }
  else if (datetype && datetype === 'halfYearly') {
    const currentHalf = currDate.getMonth() < 6 ? 1 : 2;
    const inputHalf = inputDate.getMonth() < 6 ? 1 : 2;

    if (currDate.getFullYear() < inputDate.getFullYear() || (currDate.getFullYear() === inputDate.getFullYear() && currentHalf < inputHalf)) {
      return false;
    } else {
      return true;
    }
  }

  // Check for quarterly
  else if (datetype && datetype === 'quarterly') {
    const currentQuarter = Math.floor(currDate.getMonth() / 3) + 1;
    const inputQuarter = Math.floor(inputDate.getMonth() / 3) + 1;

    if (currDate.getFullYear() < inputDate.getFullYear() || (currDate.getFullYear() === inputDate.getFullYear() && currentQuarter < inputQuarter)) {
      return false;
    } else {
      return true;
    }
  }
  else if (currDate.getFullYear() < inputDate.getFullYear() && currDate.getMonth() < inputDate.getMonth()) {
    return false;
  } else {
    return true;
  }
}

import { CreateUpdateRoleDto } from "../../dtos/employeeRoles/CreateUpdateRolesDto";
import { RoleInfoDto } from "../../dtos/employeeRoles/RoleInfoDto";
import instance, { del, get, patch, post } from "../../utills/BaseService";

export async function getPaginatedRoles(dispatch: any, input: any): Promise<any> {
   return await post(dispatch, '/employeeRole/getPaginatedRole', input);
};

export async function createRole(dispatch: any, details: CreateUpdateRoleDto): Promise<CreateUpdateRoleDto[]|string|{error:boolean,msg:string}> {
   return await post(dispatch, '/employeeRole', details);
};

export async function updateRole(dispatch: any, param: number, details: CreateUpdateRoleDto): Promise<CreateUpdateRoleDto[]|string|{error:boolean,msg:string}> {
   return await patch(dispatch, '/employeeRole', param, details);
};

export async function getRoleById(dispatch: any, input: string): Promise<RoleInfoDto> {
   return await get(dispatch, '/employeeRole/getRoleById', null, input);
};

export async function getAllRoles(dispatch:any):Promise<RoleInfoDto[]>{
   return await get(dispatch, '/employeeRole/getAllRoles', null);
}





import { Button } from 'primereact/button'
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeTheme } from '../../store/slices/themeSlice';
import { UpdateSettings } from '../../dtos/common/UpdateSettingsDto';
import { updateSettings } from '../../service/common/commonService';
import { AppModeState } from '../../dtos/common/AppModeState';
import { createTheme } from '../../service/userThemes/userThemeService';
import { getUserProfile } from '../../service/user/userService';
import { LoggedInUserData } from '../../shared/functions/LoggedInUserData';

interface ThemeProps {
    themeName: string,
    buttonColor: string
}

export const ThemeButton: React.FC<ThemeProps> = (Theme: ThemeProps) => {
    const dispatch = useDispatch();
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  
    const themeChangeHandler = async () => {
        // const updateSettingsValue: UpdateSettings = {
        //     Key: 'Theme',
        //     Value: Theme.themeName
        // }
        // await updateSettings(dispatch, updateSettingsValue)
        const userInfo = await LoggedInUserData(dispatch)
        const themeInput = {
            Theme:Theme.themeName,
            Mode:modeName,
            User:userInfo.EmployeeCode
        }
        await createTheme(dispatch,themeInput)
        dispatch(changeTheme({
            themeName: Theme.themeName,
            appMode: ''
        }));
    }

    return (
        <div>
            <Button onClick={themeChangeHandler} className='theme-button' style={{ backgroundColor: Theme.buttonColor }} />
        </div>
    )
}

import { DashboardInputDto } from "../../dtos/dashboard/DashboardInputDto";
import { EmployeeBillingInputDto } from "../../dtos/employeeBilling/EmployeeBillingInputDto";
import { InputForCreateOrUpdateEmployeeBillingDto } from "../../dtos/employeeBilling/InputForCreateOrUpdateEmployeeBillingDto";
import { get, post } from "../../utills/BaseService";

export async function getAllEmployeeBillingData(dispatch: any, input: EmployeeBillingInputDto): Promise<any> {
   return await post(dispatch, '/employeeBilling/getAllEmployeeBillingData', input);
};

export async function createEmployeeBilling(dispatch: any, input: InputForCreateOrUpdateEmployeeBillingDto): Promise<any> {
   return await post(dispatch, '/employeeBilling/createEmployeeBilling', input);
};
export async function createProjectBilling(dispatch: any, input: InputForCreateOrUpdateEmployeeBillingDto): Promise<any> {
   return await post(dispatch, '/employeeBilling/createProjectBilling', input);
};
export async function createBulkBillings(dispatch: any, input: InputForCreateOrUpdateEmployeeBillingDto[]): Promise<any> {
   return await post(dispatch, '/employeeBilling/createBulkBillings', input);
};

export async function getTopBilledEmployee(dispatch: any, input: DashboardInputDto): Promise<any> {
   return await get(dispatch, '/employeeBilling/getTopBilledEmployee', input);
};
export async function getAllBilledEmployee(dispatch: any, input: DashboardInputDto): Promise<any> {
   return await get(dispatch, '/EmployeeBilling/GetAllBilledEmployee', input);
};

export async function getTopBilledProject(dispatch: any, input: DashboardInputDto): Promise<any> {
   return await get(dispatch, '/employeeBilling/getTopBilledProject', input);
};

export async function getTopTrackingProjects(dispatch: any, input: any): Promise<any> {
   return await get(dispatch, '/employeeBilling/getTopTrackingProjects', input);
};
export async function getTopBillingProjects(dispatch: any, input: any): Promise<any> {
   return await get(dispatch, '/employeeBilling/getTopBillingProjects', input);
};

export async function getTotalTrackAndBillTimeByProj(dispatch: any, input: any): Promise<any> {
   return await get(dispatch, '/employeeBilling/getTotalTrackAndBillTimeByProj', input);
};
import { Card } from 'primereact/card';
import { Toolbar } from 'primereact/toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeState } from '../../dtos/common/ThemeState';
import { Themes } from '../../data/Themes';
import { AppModeState } from '../../dtos/common/AppModeState';
import { AppMode } from '../../data/AppMode';
import '../../css/components/project.css';
import { getProjectByUser } from '../../service/project/ProjectService';
import { useEffect, useState } from 'react';
import { GetAllProjectItemsDto } from '../../dtos/project/GetAllProjectItemsDto';
import { GetAllProjectDto } from '../../dtos/project/GetAllProjectDto';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { GetPaginationRowsCount } from '../../shared/functions/GetPaginationRowsCount';
import { GetPaginationRowsInterface } from '../../dtos/common/GetPaginationRows';
export interface LazyState {
    page: number;
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
    filters?: string;
  }

export const Project = () => {
    //#region all Variables

    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const dispatch = useDispatch();
    const localization = useSelector((state: any) => state.localization.localization);
    const [project, setProject] = useState<GetAllProjectItemsDto[]>([]);
    const [totalProjCount, setTotalProjCount] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>('');
    const row: GetPaginationRowsInterface = GetPaginationRowsCount();
    const [lazyState, setlazyState] = useState({
        page: 0,
        first: 0,
        rows:  row.rowCount,
        sortField: "createdAt",
        sortOrder: 0 as any,
      });

    //#endregion 

    //#region all Functions
 

    const getProjectsOnLoad = async () => {
       
        const projects: {items:GetAllProjectItemsDto[],totalCount:number} = await getProjectByUser(dispatch, {
            Limit: lazyState.rows,
            Page: lazyState.page,
          SearchValue:searchText?.toLowerCase()
          });
        const filteredProjects: GetAllProjectItemsDto[] = projects?.items.filter(project => {
            return project.name.toLowerCase().includes(searchText.toLowerCase())
        })
        setProject(filteredProjects);
        // setTotalProjCount(projects?.result["total_count"]);
        setTotalProjCount(projects?.totalCount);
    }

    const onPageChange = (event: any) => {
        setlazyState(event);
      };


    //#endregion 

    //#region all Templates

    const startToolbar = () => {
        return (
            <div>
                <h4>{localization?.ProjectList || 'Project List'}</h4>
                <p>{localization?.Viewallofyourprojectinformation || 'View all of your project information'}</p>
            </div>
        )
    };

    const endToolbar = () => {
        return (
            <div className="endToolbarPerformance">
                <InputText type="search" placeholder={localization?.Search || 'Search'} onChange={(e) => {
                    setTimeout(() => {
                        setSearchText(e.target.value)
                    }, 500)
                }} />
            </div>
        )
    };

    //#endregion 



    useEffect(() => {
        getProjectsOnLoad()
    }, [searchText,lazyState])

    return (
        <div className='mb-3'>
            <Toolbar start={startToolbar} end={endToolbar} style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} className='project-toolbar mb-3' />
            <Card style={{ backgroundColor: mode?.backgroundSecondaryColor }}>
                <DataTable
                    value={project}
                    totalRecords={totalProjCount}
                    showGridlines
                    paginator
                    first={lazyState.first}
                    rows={lazyState.rows}
                    lazy
                    onPage={onPageChange}
                    rowsPerPageOptions={row.rowList}
                    currentPageReportTemplate="Total Records {totalRecords}"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                >
                    <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field='name' header={localization?.Name || "Name"} sortable ></Column>
                </DataTable>
            </Card>
        </div>
    )
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Interface for the state
interface PermissionState {
    permissions: string[];
}

// Interface for the action payload (array of permissions)
interface PermissionAction {
    type: string;
    payload: string[];
}

// Initial state for permissions (empty array)
const initialState: PermissionState = {
    permissions: []
};

// Create the permissions slice
const permissionSlice = createSlice({
    name: "permissionSlice",
    initialState,
    reducers: {
        setPermissions(state: PermissionState, action: PayloadAction<string[]>) {
            state.permissions = action.payload;
        },
        clearPermissions(state: PermissionState) {
            state.permissions = [];
        }
    }
});

// Actions generated by createSlice
export const { setPermissions, clearPermissions } = permissionSlice.actions;



// Export the reducer to include it in the store
export default permissionSlice.reducer;


import { CreateUserThemeDto } from "../../dtos/userTheme/UserThemeDto";
import instance, { del, get, patch, post } from "../../utills/BaseService";

export async function createTheme(dispatch: any, details: CreateUserThemeDto): Promise<string|null> {
    return await post(dispatch, '/userTheme/createUserTheme', details);
 };
export async function getUserTheme(dispatch: any,input:{empCode:string}): Promise<{User:string,Theme:string,Mode:string}|null> {
    return await get(dispatch, '/userTheme/getUserTheme',input);
 };


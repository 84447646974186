import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { AppMode } from '../../data/AppMode';
import { AppModeState } from '../../dtos/common/AppModeState';
import colors from '../../shared/json/Colors.json';
import { ISTToDate } from '../../shared/functions/ISTToDate';

interface ser {
    name: string;
    data: any[]
}

interface props {
    data: any[];
    dateType: string;
    date: any
}

export const TopBillingProjChart = (props: props) => {
    let arrayOfVal: any[] = [];
    props.data.forEach(val => arrayOfVal.push(val.projectTimeSeries));

    arrayOfVal = arrayOfVal.flat(2);

    let TrackingDates: any[] = [];

    function getDatesBetween(startDate: Date, endDate: Date) {
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            TrackingDates.push(ISTToDate(currentDate) as string);
            currentDate.setDate(currentDate.getDate() + 1);
        }
    }

  
    function getHalfYearDatesArray(date: Date) {
        const year = date.getFullYear();
        const firstHalfStart = new Date(year, 0, 1);
        const firstHalfEnd = new Date(year, 5, 30);
        const secondHalfStart = new Date(year, 6, 1);
        const secondHalfEnd = new Date(year, 11, 31);
    
        TrackingDates.push(`Jan to Jun ${year.toString().slice(2)}`);
        TrackingDates.push(`Jul to Dec ${year.toString().slice(2)}`);
    }
    
    function getQuarterlyDatesArray(date: Date) {
        const year = date.getFullYear();
        const quarters = [
            { start: `01/${year.toString().slice(2)}`, end: `03/${year.toString().slice(2)}` },
            { start: `04/${year.toString().slice(2)}`, end: `06/${year.toString().slice(2)}` },
            { start: `07/${year.toString().slice(2)}`, end: `09/${year.toString().slice(2)}` },
            { start: `10/${year.toString().slice(2)}`, end: `12/${year.toString().slice(2)}` }
        ];
        
        quarters.forEach(q => TrackingDates.push(`${q.start} to ${q.end}`));
    }
    
    
    
    function getFirstDatesOfMonthForGivenYear(date: any) {
        const d = new Date(date.getFullYear(), 0, 1, 1)
        let i = 0;
        while (i <= 11) {
            const date = new Date(d.getFullYear(), d.getMonth() + i, 1)
            TrackingDates.push(ISTToDate(date) as string)
            i++
        }
    }

    function getFiveYearsBackFirstDay(inputDate:any) {
        const currentDate = inputDate; // Current date
        const currentYear = currentDate.getFullYear(); // Current year
    
        // First day of the year five years back
        const fiveYearsBackFirstDay = new Date(currentYear - 5, 0, 1); // January 1st of (currentYear - 5)
        TrackingDates.push(ISTToDate(fiveYearsBackFirstDay ) as string)
        
    }


    function getFirstDatesOfWeekForGivenMonth(inputDate: any) {
        var firstDay = new Date(inputDate.getFullYear(), inputDate.getMonth(), 1);
        firstDay.setDate(firstDay.getDate() - firstDay.getDay() + 1)

        TrackingDates.push(ISTToDate(firstDay) as string)

        for (let i = 0; i < 4; i++) {
            const next = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() - firstDay.getDay() + 8)
            firstDay = next
            TrackingDates.push(ISTToDate(next) as string)
        }
    }

    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);

    const ser: ser[] = []

    
    if (props.dateType === 'year') {
        getFiveYearsBackFirstDay(props.date)
      }
    if (props.dateType === 'week') {
        getFirstDatesOfWeekForGivenMonth(props.date)
    }
    if (props.dateType === 'month') {
        getFirstDatesOfMonthForGivenYear(props.date)
    }
    if (props.dateType === 'halfYearly') {
        // getFirstDatesOfMonthForGivenYear(props.date)
        getHalfYearDatesArray(props.date)
    }
    if (props.dateType === 'quarterly') {
        // getFirstDatesOfMonthForGivenYear(props.date)
        getQuarterlyDatesArray(props.date)
    }


    arrayOfVal?.forEach(data => {
        const isPresent: ser | undefined = ser.find(s => s.name.toLowerCase() === data.projectName.toLowerCase());
        if (typeof isPresent === 'undefined') {
            ser.push({
                name: data.projectName,
                data: []
            })
        }
    })

 

  

    if (props.dateType === 'halfYearly'|| props.dateType === 'quarterly') {
        TrackingDates.forEach(rangeLabel => {
            ser.forEach(s => {
                const isPresent: any = arrayOfVal?.find(data => {
                    const dataStartTime = new Date(data.startTime);
                    const isJanToJun = rangeLabel.startsWith('Jan to Jun') && dataStartTime >= new Date(dataStartTime.getFullYear(), 0, 1) && dataStartTime <= new Date(dataStartTime.getFullYear(), 5, 30);
                    const isJulToDec = rangeLabel.startsWith('Jul to Dec') && dataStartTime >= new Date(dataStartTime.getFullYear(), 6, 1) && dataStartTime <= new Date(dataStartTime.getFullYear(), 11, 31);

                    const quarters = [
                        {
                            label: `01/${dataStartTime.getFullYear().toString().slice(2)} to 03/${dataStartTime.getFullYear().toString().slice(2)}`,
                            start: new Date(dataStartTime.getFullYear(), 0, 1),
                            end: new Date(dataStartTime.getFullYear(), 2, 31)
                        },
                        {
                            label: `04/${dataStartTime.getFullYear().toString().slice(2)} to 06/${dataStartTime.getFullYear().toString().slice(2)}`,
                            start: new Date(dataStartTime.getFullYear(), 3, 1),
                            end: new Date(dataStartTime.getFullYear(), 5, 30)
                        },
                        {
                            label: `07/${dataStartTime.getFullYear().toString().slice(2)} to 09/${dataStartTime.getFullYear().toString().slice(2)}`,
                            start: new Date(dataStartTime.getFullYear(), 6, 1),
                            end: new Date(dataStartTime.getFullYear(), 8, 30)
                        },
                        {
                            label: `10/${dataStartTime.getFullYear().toString().slice(2)} to 12/${dataStartTime.getFullYear().toString().slice(2)}`,
                            start: new Date(dataStartTime.getFullYear(), 9, 1),
                            end: new Date(dataStartTime.getFullYear(), 11, 31)
                        }
                    ];
                    
                    const isWithinQuarter = quarters.some(
                        q => rangeLabel === q.label && dataStartTime >= q.start && dataStartTime <= q.end
                    );
                    
                    return (isJanToJun || isJulToDec || isWithinQuarter) && s.name.toLowerCase() === data.projectName.toLowerCase();
                });
    
                if (typeof isPresent === 'undefined') {
                    s.data.push(0);
                } else {
                    s.data.push((isPresent?.billTimeInSec / 3600).toFixed(2));
                }
            });
        });
    } else {
        TrackingDates.forEach(date => {
            ser.forEach(s => {
                const isPresent: any = arrayOfVal?.find(data => data.startTime === date && s.name.toLowerCase() === data.projectName.toLowerCase());
    
                if (typeof isPresent === 'undefined') {
                    s.data.push(0);
                } else {
                    s.data.push((isPresent?.billTimeInSec / 3600).toFixed(2));
                }
            });
        });
    }
    


    const legendColor: string[] = [];
    ser.forEach(val => legendColor.push(mode?.color as string));
    const legendColorForDates: string[] = [];
    TrackingDates.forEach(val => legendColorForDates.push(mode?.color as string));

    //for month show only date and month series
    if (props.dateType === 'week') {
        let startEndWk: string[] = [];
        TrackingDates.forEach(date => {
            let endWkDate = new Date(date);
            endWkDate = new Date(endWkDate.getFullYear(), endWkDate.getMonth(), endWkDate.getDate() - endWkDate.getDay() + 7);
            startEndWk.push(`${date.slice(5)} to ${ISTToDate(endWkDate).slice(5)}`)
        })

        TrackingDates = startEndWk
       

    }

   
    if (props.dateType === 'year') {
        const currentYear = props.date.getFullYear();
        const fiveYears = Array.from({ length: 5 }, (_, index) => currentYear - index).reverse(); // Last 5 years
        TrackingDates = fiveYears.map(year => year.toString());
    
        ser.forEach(series => {
            const dataForYears = TrackingDates.map(year => {
                const matchingData = arrayOfVal.find(data => {
                    const dataYear = new Date(data.startTime).getFullYear();
                    return dataYear.toString() === year && series.name.toLowerCase() === data.projectName.toLowerCase();
                });
                return matchingData ? (matchingData.billTimeInSec / 3600).toFixed(2) : 0; // Convert seconds to hours
            });
            series.data = dataForYears;
        });
    }

 
    

    if (props.dateType === 'month') {
        TrackingDates = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
   
  


    const chart: any = {
        series: ser,
        options: {
            chart: {
                type: 'area',
                stacked: false,
                height: 350,
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
            },
            colors: colors.colors,
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.45,
                    opacityTo: 0.05,
                    stops: [20, 100, 100, 100]
                },
            },
            xaxis: {
                type: 'category', // Change to category
                categories: TrackingDates,
                labels: {
                    style: {
                        colors: legendColorForDates
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: legendColor
                    }
                }
            },
            tooltip: {
                shared: true,
                theme: 'dark',
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                offsetX: -10,
                labels: {
                    colors: legendColor
                }
            }
        },


    };
    return (
        <ReactApexChart options={chart.options} series={chart.series} type="area" height={350} />
    )
}
// import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { getUserRole } from '../service/user/userService';
// import { useDispatch, useSelector } from 'react-redux';
// import { isPermissionGiven } from '../shared/functions/HasPermission';

// const PermissionRoutes = (props: any) => {
//     const userPermissions = useSelector((state: any) => state.permission.permissions);
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const [isAdmin, setIsAdmin] = useState(false);
//     const [hasAccess,setHasAccess] = useState(false)


//     const handlePermission = ()=>{
//         const hasPermission = isPermissionGiven(props.permissionName, userPermissions, dispatch); 
//         if(hasPermission){
//             setHasAccess(true)
//         }else{
//             setHasAccess(false)
//             navigate('/login');
//         }
//     }


//     useEffect(() => {
     
//         handlePermission()
//     }, [props.permissionName])

//     return (
//         <React.Fragment>
//             {hasAccess ? props.children : null}
//         </React.Fragment>
//     )
// }

// export default PermissionRoutes;
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserRole } from '../service/user/userService';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionDenied } from "../components/error/PermissionDenied";

const PermissionRoutes = (props: any) => {
    const userPermissions = useSelector((state: any) => state.permission.permissions);
    const navigate = useNavigate();
    const [hasAccess, setHasAccess] = useState(false);
    const [noPermissions, setNoPermissions] = useState(false); // Track if there are no permissions

    const handlePermission =async () => {
        
      
        if (!userPermissions.includes(props.permissionName) && userPermissions.length!==0) {
            // If no permissions are set, mark it and avoid further checks
            setNoPermissions(true);
            setHasAccess(false);
        } else if(userPermissions.includes(props.permissionName)){
            setHasAccess(true);
            setNoPermissions(false);
         
        }else if(userPermissions.length===0){
            setNoPermissions(true);
            setHasAccess(false);
            navigate('/login');
        }
    };

    useEffect(() => {
        handlePermission();
    }, [props.permissionName, userPermissions]); // Rerun if userPermissions change

    return (
        <React.Fragment>
            {noPermissions ? <PermissionDenied /> : hasAccess ? props.children : null}
        </React.Fragment>
    );
};

export default PermissionRoutes;

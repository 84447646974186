import { setError } from "../../store/slices/toastSlice";

// Exported function that takes permissions and dispatch as arguments
export function isPermissionGiven(
  permissionString: string,
  userPermissions: string[],
  dispatch: any
) {
 
  if (!userPermissions.includes(permissionString)) {
    dispatch(
      setError({
        isError: true,
        errorMessage: "You don't have the required permissions to access this feature.",
      })
    );
    return false; // Return false if permission is not given
  }
  dispatch(setError({ isError: false, errorMessage: "" }));
  return true; // Return true if permission is given
  
}


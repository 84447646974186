import { CreateKPIMappings } from "../../dtos/kpiMappings/CreateKPIMappingsDto";
import { GetKpiMappingsByUserInputDto } from "../../dtos/kpiMappings/GetKpiMappingsByUserInputDto";
import { del, get, post } from "../../utills/BaseService";


export async function createKpiMappings(dispatch: any, details: CreateKPIMappings): Promise<CreateKPIMappings[]> {
   return await post(dispatch, '/kpiMappings/createKpiMappings', details);
};
export async function createUserMappings(dispatch: any, details: CreateKPIMappings): Promise<CreateKPIMappings[]> {
   return await post(dispatch, '/kpiMappings/createUserMappings', details);
};

export async function getAllKpiMappings(dispatch: any, input: any): Promise<any> {
   return await get(dispatch, '/kpiMappings/getAllKpiMappings', input);
};
export async function isMappingPresent(dispatch: any, input: any): Promise<any> {
   return await get(dispatch, '/kpiMappings/isMappingPresent', input);
};
export async function deleteKpiMappingsByRoleName(dispatch: any, input: any): Promise<any> {
   return await del(dispatch, '/kpiMappings/deleteKpiMappingsByRoleName', input);
};
export async function deleteKpiMappingsByEmployeeCode(dispatch: any, input: any): Promise<any> {
   return await del(dispatch, '/kpiMappings/deleteKpiMappingsByEmployeeCode', input);
};
export async function getAllRoleFromKpiMappings(dispatch: any): Promise<any> {
   return await get(dispatch, '/kpiMappings/getAllRoleFromKpiMappings', null);
};
export async function getKpiMappingsByUser(dispatch: any, input: GetKpiMappingsByUserInputDto): Promise<any> {
   return await get(dispatch, '/kpiMappings/getKpiMappingsByUser', input);
};
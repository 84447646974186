import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserRole } from '../service/user/userService';
import { useDispatch, useSelector } from 'react-redux';
import { isPermissionGiven } from '../shared/functions/HasPermission';

const ProtectAdminRoutes = (props: any) => {
    const userPermissions = useSelector((state: any) => state.permission.permissions);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isAdmin, setIsAdmin] = useState(false);
    const [hasAccess,setHasAccess] = useState(false)
    // const chekUserRole = (role:string) => {
    //     if (role?.toUpperCase() === 'ADMIN') {
    //         setIsAdmin(true);
    //     }
    //     else {
    //         setIsAdmin(false);
    //         navigate('/dashboard');
    //     }
    // }
    // const fetchRole = async () => {
    //     const roleData = await getUserRole(dispatch);
    //     chekUserRole(roleData)
    // }

    const handlePermission = ()=>{
        const hasPermission = isPermissionGiven(props.permissionName, userPermissions, dispatch); 
        if(hasPermission){
            setHasAccess(true)
        }else{
            setHasAccess(false)
            navigate('/dashboard');
        }
    }

    // useEffect(() => {
    //     chekUserRole();
    // }, [role])
    useEffect(() => {
        // fetchRole();
        handlePermission()
    }, [props.permissionName])

    return (
        <React.Fragment>
            {hasAccess ? props.children : null}
        </React.Fragment>
    )
}

export default ProtectAdminRoutes;